import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        discounts: [],
        selectedDiscount: null
    },

    getters: {
        discounts: state => state.discounts,
        selectedDiscount: state => state.selectedDiscount
    },

    mutations: {
        SET_DISCOUNTS: (state, payload) => state.discounts = payload,
        SELECT_DISCOUNT: (state, payload) => {
            console.log(payload)
            state.selectedDiscount = payload
            console.log('from mutation: ', state.selectedDiscount)
        }
    },

    actions: {
        async fetchDiscounts({ commit }) {
            let { data } = await axios.get('discounts')

            commit('SET_DISCOUNTS', data.discounts)
        }
    }
}