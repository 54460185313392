<template>
  <b-modal id="client-modal">
    <template v-slot:modal-header="">
      <h5>العميل</h5>
    </template>

    <template v-slot:default="">
      <div class="form-group text-right">
        <label class="small">الاسم:</label>
        <input class="form-control" placeholder="الاسم" v-model="client.name" />
        <div v-if="!client.name" class="small text-red">الإدخال مطلوب.</div>
      </div>

      <div class="form-group mt-3 text-right">
        <label class="small">رقم الجوال:</label>
        <input
          class="form-control"
          placeholder="رقم الجوال"
          type="text"
          v-model="client.mobile"
        />
        <div v-if="!client.mobile" class="small text-red">الإدخال مطلوب.</div>
      </div>

      <!-- <div class="form-group mt-3 text-right">
        <label class="small">البريد الإلكتروني:</label>
        <input
          class="form-control"
          placeholder="البريد الإلكتروني"
          type="email"
          v-model="client.email"
        />
        <div v-if="!client.email" class="small text-red">الإدخال مطلوب.</div>
      </div> -->

      <div class="form-group mt-3 text-right">
        <label class="small">عنوان العمل:</label>
        <textarea
          class="form-control"
          placeholder="عنوان العمل"
          v-model="client.workAddress"
        ></textarea>
        <div v-if="!client.workAddress" class="small text-red">
          الإدخال مطلوب.
        </div>
      </div>

      <div class="form-group mt-3 text-right">
        <label class="small">رقم الهوية:</label>
        <input
          class="form-control"
          placeholder="رقم الهوية"
          v-model="client.idNumber"
        />
        <div v-if="!client.idNumber" class="small text-red">الإدخال مطلوب.</div>
      </div>

      <div class="form-group mt-3 text-right">
        <label class="small">كلمة المرور:</label>
        <input
          class="form-control"
          placeholder="كلمة المرور"
          v-model="client.password"
          type="password"
        />
        <div v-if="!client.password && !selectedClient" class="small text-red">الإدخال مطلوب.</div>
      </div>

      <div class="form-group mt-3 text-right">
        <label class="small">تأكيد كلمة المرور:</label>
        <input
          class="form-control"
          placeholder="تأكيد كلمة المرور"
          v-model="client.password_confirmation"
          type="password"
        />
        <div v-if="!client.password_confirmation && !selectedClient" class="small text-red">الإدخال مطلوب.</div>
      </div>

      <div
        class="pointer d-inline-block mt-3"
        @click="$refs.idCardImage.click()"
      >
        <div class="m-auto">
          <img
            ref="idCardPreview"
            class="id-card-preview m-auto"
            :src="getImage"
            alt=""
          />
        </div>
        <span class="text-muted small">تحديث صورة الهوية</span>
        <input
          ref="idCardImage"
          type="file"
          style="display: none"
          @change="updateImage"
          accept="image/*"
        />
      </div>
    </template>

    <template v-slot:modal-footer="">
      <button type="submit" class="btn btn-primary" @click="submit">حفظ</button>
      <a
        href="#"
        class="text-muted"
        @click="$bvModal.hide('client-modal')"
        >إغلاق</a
      >
    </template>
  </b-modal>
</template>

<script>
import ClientsMixin from "@/mixins/clients";

export default {
  mixins: [ClientsMixin],

  data() {
    return {
      client: {
        name: null,
        // email: null,
        mobile: null,
        workAddress: null,
        idNumber: null,
        password: null,
        password_confirmation: null,
        image: {
          link: null
        },
      },
    };
  },

  mounted() {
    this.buildClient();
  },

  methods: {
    buildClient() {
      if (this.selectedClient) {
        // this.client.name = this.client.name;
        // this.client.email = this.client.email;
        // this.client.mobile = this.client.mobile;
        // this.client.workAddress = this.client.workAddress;
        // this.client.idNumber = this.client.idNumber;
        // this.client.image = this.client.image;
        Object.keys(this.client).forEach((k) => (this.client[k] = this.selectedClient[k]));
        if (this.selectedClient.image) {
          this.client.image.link = this.selectedClient.image.link
        }

      } else {
        Object.keys(this.client).forEach((k) => (this.client[k] = null));
      }
    },
    submit() {
      this.saveClient(this.client).then((res) => {
        if (res.success) {
          // this.showSwal(res)
          if (this.$refs.idCardImage.files[0]) {
            this.saveClientImage(this.$refs.idCardImage.files[0]).then(
              (res2) => {
                if (res2.success) this.$bvModal.hide("client-modal");
              }
            );
          } else {
            this.showSwal(res);
            this.$bvModal.hide("client-modal");
          }
        } else {
          this.showSwal(res);
        }
      });
    },

    updateImage(e) {
      this.$refs.idCardPreview.src = URL.createObjectURL(e.target.files[0]);
    },
  },

  computed: {
    getImage () {
      return this.client.image ? this.client.image.link : require('@/assets/images/image-placeholder.png')
    }
  },

  watch: {
    selectedClient() {
      this.buildClient();
    },
  },
};
</script>