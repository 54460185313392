import { mapActions, mapGetters } from 'vuex';

export default {
    methods: {
        ...mapActions({
            // updateCategories: 'categories/updateCategories',
            // deleteCategory: 'categories/deleteCategory',
            // addCategory: 'categories/addCategory'
        })
    },

    computed: {
        ...mapGetters({
            categories: 'categories/categories'
        })
    }
}