<template>
  <li>
    <a href="#!" @click.stop="showModal">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4 panel-info text-center text-sm-right">
          <!-- <div @click.stop class="d-inline">
            <b-form-checkbox
              class="d-inline pl-0"
              v-model="leaseRequest.selected"
              :value="true"
              @change.native="toggleleaseRequestSelection(leaseRequest)"
            >
            </b-form-checkbox>
          </div> -->
          <!-- <div v-else class="d-inline-block" style="width: 24px"></div> -->
            <span class="text-muted small">اسم المستأجر: </span>

          {{ leaseRequest.residentName }}
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center">
            <span class="text-muted small">جوال المستأجر: </span>
          {{ leaseRequest.residentMobile }}
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center">
            <span class="text-muted small">نوع العقد: </span>
          <!-- {{ leaseRequest.type }} -->
          <Translate :phrase="leaseRequest.type" />
        </div>

        <div class="col-12 col-sm-6 col-md-4 panel-info text-center">
          {{ getDate(leaseRequest.created_at) }}
        </div>
      </div>
    </a>
  </li>
</template>

<script>
import LeaseRequestsMixin from '@/mixins/leaseRequests'

export default {
  mixins: [LeaseRequestsMixin],

  data() {
    return {
      showDetails: false,
    };
  },

  methods: {
      showModal () {
          this.selectLeaseRequest(this.leaseRequest)
          if (this.leaseRequest.type == 'residential') {
              this.$bvModal.show('residential-lease-modal')
          } else if (this.leaseRequest.type == 'commercial') {
              this.$bvModal.show('commercial-lease-modal')
          } else if (this.leaseRequest.type == 'sub') {
              this.$bvModal.show('sub-lease-modal')
          }
      }
  },

  props: ["leaseRequest"],
};
</script>