<template>
  <div>
    <div class="text-right mb-3">
      <button class="btn btn-primary btn-sm" @click="addClientTap" title="إضافة عميل">
        <fa icon="plus" class="ml-0 ml-sm-2" />
        <span class="d-none d-sm-inline-block">إضافة عميل</span>
      </button>

      <!-- <button
        class="btn btn-primary btn-sm mr-3"
        @click="saveNotificationTap"
        title="إرسال إشعار"
      >
        <fa icon="bell" class="ml-0 ml-sm-2" />
        <span class="d-none d-sm-inline-block">إرسال إشعار</span>
      </button> -->

      <button class="btn btn-primary btn-sm mr-3 float-left" @click="exportClientsTap" title="تنزيل">
        <fa icon="download" class="ml-0 ml-sm-2" />
        <span class="d-none d-sm-inline-block">تنزيل</span>
      </button>
    </div>

    <div class="container clearfix">
      <div class="form-group float-right mt-3">
        <input type="text" class="form-control" placeholder="البحث" v-model="searchTerm" />
      </div>
    </div>

    <PanelList :items="finalClients" title="العملاء" :isAllSelected="isAllSelected"
      @toggle-items-selection="toggleClientsSelection()" @delete-selected="deleteSelectedClients()" :canSelectAll="false">
      <ClientPanelItem v-for="(client, i) in finalClients" :key="i" :client="client"
        @show-agreement-tap="showAgreementTap($event)" />
    </PanelList>

    <ClientModal />
    <NotificationModal />
    <AgreementModal />
  </div>
</template>

<script>
import PanelList from "@/components/general/panel-list";
import ClientPanelItem from "@/components/panel-items/client";
import ClientModal from "@/components/modals/client";
import ClientsMixin from "@/mixins/clients";
import AgentsMixin from "@/mixins/agents";
import NotificationModal from "@/components/modals/notification";
import AgreementsMixin from "@/mixins/agreements";
import AgreementModal from "@/components/modals/agreement";
import HashmanagerMixin from "@/mixins/hashmanager";
import AuthMixin from "@/mixins/auth"

export default {
  mixins: [AgentsMixin, ClientsMixin, AgreementsMixin, HashmanagerMixin, AuthMixin],

  data() {
    return {
      searchTerm: ''
    }
  },

  methods: {
    addClientTap() {
      this.selectClient(null);
      this.$bvModal.show("client-modal");
    },

    saveNotificationTap() {
      this.selectAgent(null);
      this.selectClient(null);
      this.$bvModal.show("notification-modal");
    },

    showAgreementTap(client) {
      this.selectAgreementByClientId(client.id);
      this.$bvModal.show("agreement-modal");
    },

    exportClientsTap() {
      this.storeHash().then((res) => {
        if (res.success) {
          window.open(
            `https://api.athbat.com/agent/exportClients/${res.hash}/${this.user.id}`
          );
        }
      });
    },
  },

  computed: {
    isAllSelected() {
      return this.clients.length == this.selectedClients.length;
    },

    finalClients() {
      if (!this.searchTerm) {
        return this.clients
      }

      return this.clients.filter(c => c.name.indexOf(this.searchTerm) > -1)
    }
  },

  components: {
    PanelList,
    ClientPanelItem,
    ClientModal,
    NotificationModal,
    AgreementModal,
  },
};
</script>