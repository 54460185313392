<template>
  <div>
    <div class="text-right mb-3">
      <button class="btn btn-sm btn-primary" @click="$refs.imagesInput.click()">
        <fa icon="plus" class="ml-2" />
        <span>إضافة صور</span>
      </button>
      <input
        type="file"
        ref="imagesInput"
        style="display: none"
        :multiple="multiple"
        accept="image/*"
        @change="$emit('images-changed', $event.target.files)"
      />
    </div>
    <div class="images-wrapper d-flex flex-wrap">
      <div v-for="(image, i) in images" :key="i" class="image-wrapper">
        <div class="image-control-item" @click="$emit('delete-image', image)">
          <fa icon="trash" />
        </div>
        <img v-lazy="image.src || image.link" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        images: {
            type: Array,
            default: () => []
        },
        multiple: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style lang="scss" scoped>

.image-wrapper {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 6px;
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, .2);
    margin: 15px;
    margin-top: 0;
    .image-control-item {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px;
        background-color: rgba(0,0,0, .5);
        // background-color: rgba(255, 255, 255, .2);
        // background-color: rgba(189, 189, 189, .2);
        border-radius: 6px 0 6px 0;
        cursor: pointer;
        .fa-trash {
            color: red;
        }
    }
    img {
        width: 100%;
        height: 100%;
        border-radius: 6px
    }
}
</style>