<template>
    <div class="d-felx justify-content-center align-items-center">
        <h4>التحقق من البريد الإلكتروني</h4>
    </div>
</template>

<script>
import AuthMixin from "@/mixins/auth";

export default {
  mixins: [AuthMixin],

  data() {
    return {
      form: {
        email: this.$route.params.email,
        token: this.$route.params.token,
      },
    };
  },

  mounted() {
    this.verifyEmail(this.form).then((res) => {
        this.showSwal(res)
        this.$router.push({ name: 'login' })
        console.log(res)
    });
  },
};
</script>