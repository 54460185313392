<template>
  <div>
    <div class="container">
      <button
        class="btn btn-primary btn-sm float-right"
        @click="
          $router.push({
            name: 'properties.show',
            params: { propertyNumber: 'create' },
          })
        "
      >
        <fa icon="plus" class="ml-2" />
        <span>إضافة عقار</span>
      </button>

      <div class="mt-3 mt-sm-0 float-right float-sm-left">
        <div
          style="
            border: 1px solid rgba(0, 0, 0, 0.1);
            width: 15px;
            height: 15px;
          "
          class="bg-light d-inline-block mx-2"
        ></div>
        <span>غير مؤجر</span>
        <div
          style="
            border: 1px solid rgba(0, 0, 0, 0.1);
            width: 15px;
            height: 15px;
          "
          class="bg-success d-inline-block mx-2"
        ></div>
        <span>مؤجر</span>
      </div>
    </div>

    <div class="container d-flex flex-wrap properties-wrapper">
      <a href="" @click.prevent="showProperty(property)"
        v-for="(property, i) in properties"
        :key="i"
        class="property-item d-flex align-items-center justify-content-center pointer"
        :class="{
          'bg-success text-white': property.client_id,
          'bg-light text-black': !property.client_id,
        }"
      >
        <span>{{ property.number }}</span>

        <!-- <div class="property-info" :class="{ empty: !property.client_id }">
          <div class="float-left" title="أعمال الصيانة">
            <fa icon="wrench" class="fa-xs" />
            {{ property.maintenances.length }}
          </div>
          <div class="flaot-right">
            <div v-if="property.contract && !property.contract.isPaid">
              <fa icon="exclamation" class="ml-2 fa-xs" />
              <span class="payment-status">توجد مستحقات</span>
            </div>
            <div v-else>
              <fa icon="check" class="ml-2 fa-xs" />
            </div>
          </div>
        </div> -->
      </a>
    </div>
  </div>
</template>

<script>
import PropertiesMixin from "@/mixins/properties";

export default {
  mixins: [PropertiesMixin],

  data() {
    return {};
  },

  methods: {
    showProperty (property) {
      this.selectProperty(property)
      this.$router.push({ name: 'properties.show', params: { propertyNumber: property.number } })
    }
  }
};
</script>