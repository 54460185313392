import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        notifications: [],
        selectedNotification: null
    },

    getters: {
        notifications: state => state.notifications,
        selectedNotification: state => state.selectedNotification,
        selectedNotifications: state => {
            return state.notifications.filter(n => n.selected)
        }
    },

    mutations: {
        SET_NOTIFICATIONS: (state, payload) => state.notifications = payload,
        SET_SELECTED_NOTIFICATION: (state, payload) => state.selectedNotification = payload,
        SELECT_ALL: state => {
            state.notifications.forEach(m => m.selected = true)
        },
        DESELECT_ALL: state => {
            state.notifications.forEach(m => m.selected = false)
        },
        SELECT_NOTIFICATION: (state, payload) => {
            state.notifications[state.notifications.indexOf(payload)].selected = true
        },

        DESELECT_NOTIFICATION: (state, payload) => {
            state.notifications[state.notifications.indexOf(payload)].selected = false
        },
    },

    actions: {
        async fetchNotifications({ commit }) {
            let { data } = await axios.get('agent/notifications')
            data.notifications.forEach(n => n.selected = false)
            commit('SET_NOTIFICATIONS', data.notifications)
        },

        toggleNotificationsSelection({ commit, getters, state }) {
            if (getters.selectedNotifications.length < state.notifications.length) {
                commit('SELECT_ALL')
            } else {
                commit('DESELECT_ALL')
            }
        },

        toggleNotificationSelection({ commit, state }, payload) {
            let currentState = state.notifications[state.notifications.indexOf(payload)].selected
            if (currentState) {
                commit('SELECT_NOTIFICATION', payload)
            } else {
                commit('DESELECT_NOTIFICATION', payload)
            }
        },

        async deleteSelectedNotifications({ getters, dispatch }) {
            let ids = []
            getters.selectedNotifications.forEach(doc => ids.push(doc.id))
            let { data } = await axios.delete('agent/notifications', { params: { ids } })
            dispatch('fetchNotifications')
            return data
        },

        async clearNotificationsCounter({ rootState }) {
            let { data } = await axios.post('agent/notificationsCounter')

            rootState.auth.user.notificationsCounter = 0

            return data
        }
    }
}