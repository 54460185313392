import axios from '@/axios'

export default {
    namespaced: true,

    state: {

    },

    getters: {

    },

    mutations: {

    },

    actions: {
        async storeHash() {
            let { data } = await axios.get('agent/hashmanager')

            return data
        }
    }
}