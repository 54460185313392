import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        clients: [],
        selectedClient: null,
        searchTerm: null
    },

    getters: {
        clients: state => state.clients,
        selectedClient: state => state.selectedClient,
        selectedClients: state => {
            return state.clients.filter(m => m.selected)
        },
        clientsFilter: state => {
            return state.clients.filter(c => c.name.indexOf(state.searchTerm) > -1)
        },
        searchTerm: state => state.searchTerm
    },

    mutations: {
        SET_CLIENTS: (state, payload) => state.clients = payload,
        SET_SELECTED_CLIENT: (state, payload) => state.selectedClient = payload,
        SELECT_ALL: state => {
            state.clients.forEach(m => m.selected = true)
        },
        DESELECT_ALL: state => {
            state.clients.forEach(m => m.selected = false)
        },
        SELECT_CLIENT: (state, payload) => {
            state.clients[state.clients.indexOf(payload)].selected = true
        },

        DESELECT_CLIENT: (state, payload) => {
            state.clients[state.clients.indexOf(payload)].selected = false
        },
        SET_SEARCH_TERM: (state, payload) => state.searchTerm = payload
    },

    actions: {
        async fetchClients({ commit }) {
            let { data } = await axios.get('agent/clients')
            data.clients.forEach(m => m.selected = false)
            commit('SET_CLIENTS', data.clients)

        },

        toggleClientsSelection({ commit, getters, state }) {
            if (getters.selectedClients.length < state.clients.length) {
                commit('SELECT_ALL')
            } else {
                commit('DESELECT_ALL')
            }
        },

        toggleClientSelection({ commit, state }, payload) {
            let currentState = state.clients[state.clients.indexOf(payload)].selected
            if (currentState) {
                commit('SELECT_CLIENT', payload)
            } else {
                commit('DESELECT_CLIENT', payload)
            }
        },

        async deleteSelectedClients({ getters, dispatch }) {
            let ids = []
            getters.selectedClients.forEach(doc => ids.push(doc.id))
            let { data } = await axios.delete('agent/clients', { params: { ids } })
            dispatch('fetchClients')
            dispatch('properties/fetchProperties', {}, { root: true })
            return data
        },

        async saveClient({ commit, dispatch, getters }, payload) {
            let url = getters.selectedClient ? `agent/clients/${getters.selectedClient.id}` : `agent/clients`
            payload.role = 'user'
            let { data } = await axios({
                method: getters.selectedClient ? 'put' : 'post',
                url,
                data: payload
            })

            if (data.success) {
                dispatch('fetchClients')
                commit('SET_SELECTED_CLIENT', data.client)
            }

            return data
        },

        async saveClientImage({ getters, dispatch }, image) {
            let formData = new FormData()
            formData.append('images[0]', image)
            formData.append('user_id', getters.selectedClient.id)
            let { data } = await axios.post('agent/images', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })

            if (data.success) {
                dispatch('fetchClients')
            }

            return data
        },

        async exportClients() {
            try {
                let { data } = await axios.get('agent/clientsExport', {
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/pdf'
                    }
                })

                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'العملاء.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();

                console.log(data)

                return data
            } catch (e) {
                console.log(e)
            }
        }
    }
}