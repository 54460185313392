<template>
  <div>
    <PanelList
      class="mt-5"
      :items="notifications"
      title="الإشعارات"
      :isAllSelected="isAllSelected"
      @toggle-items-selection="toggleNotificationsSelection()"
      :canSelectAll="false"
    >
      <NotificationPanelItem
        v-for="(notification, i) in notifications"
        :key="i"
        :notification="notification"
      />
    </PanelList>

    <NotificationModal />
  </div>
</template>

<script>
import PanelList from "@/components/general/panel-list";
import NotificationPanelItem from "@/components/panel-items/notification";
import NotificationModal from "@/components/modals/notification";
import NotificationsMixin from '@/mixins/notifications'

export default {
  mixins: [NotificationsMixin],

  mounted () {
    this.clearNotificationsCounter()
  },
  
  components: {
    PanelList,
    NotificationPanelItem,
    NotificationModal,
  },
};
</script>