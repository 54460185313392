import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    methods: {
        ...mapActions({
            // saveCommission: 'commissions/saveCommission',
            // toggleCommissionsSelection: 'commissions/toggleCommissionsSelection',
            // toggleCommissionSelection: 'commissions/toggleCommissionSelection',
            // deleteSelectedCommissions: 'commissions/deleteSelectedCommissions',
        }),

        ...mapMutations({
            // selectCommission: 'commissions/SET_SELECTED_COMMISSION',
        })
    },

    computed: {
        ...mapGetters({
            commissions: 'commissions/commissions',
            // selectedCommission: 'commissions/selectedCommission',
            // selectedCommissions: 'commissions/selectedCommissions',
        })
    }
}