<template>
  <b-modal id="bill-modal">
    <template v-slot:modal-header="">
      <h5>الفاتورة</h5>
    </template>

    <template v-slot:default="">
      <div class="form-group">
        <label for="nameAr" class="text-muted">حدد الموظف</label>

        <multiselect
          dir="rtl"
          class="text-right"
          v-model="bill.agent"
          :options="agents"
          placeholder="حدد الموظف"
          label="name"
          track-by="name"
          selectLabel="اضغظ Enter للتحديد"
          deselectLabel="اضغط Enter لإلغاء التحديد"
          autofocus
        >
          <template><span slot="noResult">لا توجد نتائج</span></template>
          <template><span slot="noOptions">لا توجد خيارات</span></template>
        </multiselect>
      </div>

      <div class="form-group mt-3 text-right">
        <label class="small">التفاصيل:</label>
        <textarea
          class="form-control"
          placeholder="التفاصيل"
          rows="5"
          v-model="bill.body"
        ></textarea>
      </div>

      <div class="form-group mt-3 text-right">
        <label class="small">الصور:</label>
        <ImagesWrapper
          :images="allImages"
          @images-changed="updateImages($event)"
          @delete-image="deleteImageTap($event)"
        />
      </div>
    </template>

    <template v-slot:modal-footer="">
      <button type="submit" class="btn btn-primary" @click="submit">حفظ</button>
      <a
        href="#"
        class="text-muted"
        @click.prevent="$bvModal.hide('bill-modal')"
        >إغلاق</a
      >
    </template>
  </b-modal>
</template>

<script>
import BillsMixin from "@/mixins/bills";
import PropertiesMixin from "@/mixins/properties";
import AgentsMixin from "@/mixins/agents";

export default {
  mixins: [BillsMixin, PropertiesMixin, AgentsMixin],

  data() {
    return {
      bill: {
        body: null,
        images: [],
        agent: {}
      },
      newImages: [],
    };
  },

  mounted() {
    this.buildBill();
  },

  methods: {
    buildBill() {
      if (this.selectedBill) {
        this.bill.body = this.selectedBill.body;
        this.bill.images = this.selectedBill.images;
        this.bill.agent = this.agents.find(
          (a) => a.id == this.selectedBill.agent_id
        );
      } else {
        this.bill.body = null;
        this.bill.images = [];
        this.newImages = [];
        this.bill.agent = {}
      }
    },

    submit() {
      this.bill.images = this.newImages;
      this.saveBill(this.bill).then((res) => {
        console.log("return res: ", res);
        if (res.success) {
          this.$bvModal.hide("bill-modal");
          // this.showSwal(res)
          // this.selectedProperty.maintenances.push(this.bill);
        } else {
          this.showSwal(res);
        }
        this.newImages = [];
      });
    },

    updateImages(files) {
      files.forEach((file) => {
        // file.src = URL.createObjectURL(file);
        this.newImages.push(file);
      });
    },

    deleteImageTap(image) {
      // console.log(image)
      let foundImage = this.selectedBill
        ? this.selectedBill.images.find((img) => img == image)
        : null;

      if (foundImage) {
        this.deleteImage(image).then((res) => {
          if (res.success) {
            this.selectedBill.images.splice(
              this.selectedBill.images.indexOf(image),
              1
            );
          }
        });
      } else {
        this.newImages.splice(this.newImages.indexOf(image), 1);
      }
    },
  },

  computed: {
    allImages() {
      this.newImages.forEach((newImage) => {
        newImage.src = URL.createObjectURL(newImage);
      });
      return this.bill.images
        ? this.bill.images.concat(this.newImages)
        : this.newImages;
    },
  },

  watch: {
    selectedBill() {
      this.buildBill();
    },
  },
};
</script>